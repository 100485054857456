$('.accordion .accordion__section').each( function () {
	const $head = $(this).find('.accordion__head-actions');
	const $body = $(this).find('.accordion__body-actions');

	$head.on('click', (e) => {
		e.preventDefault();
		$head.slideUp();
		$(this).find('.accordion__body').slideDown();
	});

	$body.on('click', (e) => {
		e.preventDefault();
		$head.slideDown();
		$(this).find('.accordion__body').slideUp();
	});
})
