/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */
import './modules/hero-fullheight';
import './modules/slider-logos';
import './modules/slider-testimonials';
import './modules/acc';
import './modules/nav';
import './modules/add-body-classes';
import './modules/examples';

