import { $win } from '../utils/globals';

$win.on('load', function() {
	setTimeout(() => {
		$('body').addClass('loaded');
	}, 100);
});

$win.on('scroll load', function() {
	const scroll = $win.scrollTop();

	if (scroll >= 50) {
		$('body').addClass('has-fixed-header');
	} else {
		$('body').removeClass('has-fixed-header');
	}
})
