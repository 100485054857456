import 'slick-carousel';

$('.js-slider-testimonials .slider__slides').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: true,
	autoplay: true,
	autoplaySpeed: 5000,
});
