import 'slick-carousel';

$('.js-slider-logos .slider__slides').slick({
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: true,
	pauseOnHover: false,
	responsive: [
		{
		breakpoint: 1023,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			}
		},
		{
		breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		}
	]
});
