import { $win } from '../utils/globals';

$('.btn-menu').on('click', function(e) {
	e.preventDefault();

	$('.header .header__inner').slideToggle();
})

if ( $win.width() < 1023 ) {
	mobileNavigation();
}

function mobileNavigation () {
	$('.menu-item-has-children > a').on('click', function(e) {
		if ( !$(this).parent().hasClass('js-show') ) {
			e.preventDefault();

			$(this)
				.next()
				.slideDown()
				.parent()
				.addClass('js-show')
				.siblings()
				.removeClass('js-show')
				.find('.sub-menu')
				.slideUp()
				.find('.js-show')
				.removeClass('js-show');
		}
	});
}
