import { $win } from '../utils/globals';

$.fn.equalizeHeight = function() {
	var maxHeight = 0, itemHeight;

	for (var i = 0; i < this.length; i++) {
		itemHeight = $(this[i]).height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}
	return this.height(maxHeight);
}

$win.on('load', function() {
	$('.section-example-type--web .section__content').equalizeHeight();
});

$win.on('resize', function() {
	$('.section-example-type--web .section__content').removeAttr('style').equalizeHeight();
});


// Get the button
let mybutton = document.getElementById("myBtn");

// Ensure the button exists before adding event listeners
if (mybutton) {
	// When the user scrolls down 20px from the top of the document, show or hide the button
	window.addEventListener('scroll', function() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			mybutton.style.display = "block";
		} else {
			mybutton.style.display = "none";
		}
	});

	// Function to scroll to the top of the document smoothly
	function topFunction() {
		// Adding smooth scroll back to the top
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	// When the user clicks on the button, scroll to the top of the document
	mybutton.addEventListener('click', topFunction);
}
